import { BsTelegram } from "react-icons/bs"
import { BsCopy } from "react-icons/bs"
import { BsCollection } from "react-icons/bs"

const GetStartedData = [
    {
       icon: <BsTelegram className="get-started-icon1"/>, 
       name: "Join Telegram",
       about: "Join a telegram community of over 1000 members ready to help you out.",
       button: "JOIN TELEGRAM",
       link: "https://t.me/Dex_Sniffer"
    },
    {
       icon: <BsCollection className="get-started-icon2"/>,
       name: "Get DexSniffer",
       about: "Verify your wallet and start using DexSniffer NOW!",
       button: "LAUNCH BOT",
       link: "https://t.me/DexSnifferBot"
    },
    {
        icon: <BsCopy id="filtersmall" className="get-started-icon3"/>,
        name: "Discover Dexsniffer",
        about: "Get Educated about everything Dexsniffer",
        button: "DOCUMENTATION",
        link: "https://docs.dexsniffer.io/"
    }

]

export default GetStartedData